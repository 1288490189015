import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  ConnectionProvider,
  WalletProvider as WalletAdapterProvider,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { Connection } from '@solana/web3.js';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import '@solana/wallet-adapter-react-ui/styles.css';
import { env } from '@/env';

export const SOLANA_NETWORK =
  (env.VITE_PUBLIC_NETWORKS_MODE ?? 'mainnet') === 'testnet'
    ? WalletAdapterNetwork.Devnet
    : WalletAdapterNetwork.Mainnet;

// You can also provide a custom RPC endpoint.
const endpoint =
  env.VITE_SOLANA_RPC_ENDPOINT || 'https://solana-rpc.publicnode.com';

export const connection = new Connection(endpoint, 'confirmed');

const WalletProvider: FC<PropsWithChildren> = ({ children }) => {
  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/solana-labs/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */

      new TorusWalletAdapter(),
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
    ],
    [],
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletAdapterProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletAdapterProvider>
    </ConnectionProvider>
  );
};

export default WalletProvider;
